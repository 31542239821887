//获取社群列表
const getAssociaListUrl = `/gateway/hc-neighbour/association/getList`;

//获取社群分类
const getAssociaKindsListUrl = `/gateway/hc-neighbour/association/getInitInfo`;
//删除社群分类
const getDeleteListUrl = `/gateway/hc-neighbour/association/delete`;
// 新增社群分类
const getKindsUrl = `/gateway/hc-neighbour/association/add`;
//  更新社群分类
const updateKindsUrl = `/gateway/hc-neighbour/association/update`;
//  获取社群分类详情
const getKindsDetailUrl = `/gateway/hc-neighbour/association/getDetailInfoById`;

//社群申请订单 
const getAuditListUrl = `/gateway/hc-neighbour/association/getAuditList`;
// 申请订单详情
const getDetailUrl = `/gateway/hc-neighbour/association/getDetail`;
// 社群申请审核
const auditUrl = `/gateway/hc-neighbour/association/audit`;
//获取负责人信息
const getDirectorUrl = `/gateway/hc-neighbour/association/getDirector`;

export {
  getAssociaListUrl,
  getAssociaKindsListUrl,
  getDeleteListUrl,
  getKindsUrl,
  getKindsDetailUrl,
  updateKindsUrl,
  getAuditListUrl,
  getDetailUrl,
  auditUrl,
  getDirectorUrl,
};
