import { mapHelper } from "@/utils/common.js";

const status = [
  {
    value: 1,
    label: "发布",
  },
  {
    value: 3,
    label: "取消",
  },
];

const { map: statusMap, setOps: setStatusOps } = mapHelper.setMap(status);

const isTop = [
  {
    value: 0,
    label: "否",
  },
  {
    value: 1,
    label: "是",
  },
];

const { map: isTopMap, setOps: setIsTopOps } = mapHelper.setMap(isTop);

// const talentType = [
//   {
//     value: "",
//     label: "创业社区达人",
//   },
//   {
//     value: "",
//     label: "",
//   },
//   {
//     value: "",
//     label: "",
//   },
//   {
//     value: "",
//     label: "",
//   },
// ];

// const { map: talentTypeMap, setOps: setTalentTypeOps } =
//   mapHelper.setMap(talentType);
//社群组织方
const associationOrg = [
  {
    value: 1,
    label: "政府组织",
  },
  {
    value: 2,
    label: "社会组织",
  },
  {
    value: 3,
    label: "业主自发",
  },
];

const { map: associationOrgMap, setOps: associationOrgOps } = mapHelper.setMap(associationOrg);

// 审核状态
const applyStatus=[
  {
    value:0,
    label:'待审核'
  },
  {
    value:1,
    label:'审核通过'
  },
  {
    value:2,
    label:'审核失败'
  },
]
const applyStatus_d=[
  {
    value:1,
    label:'审核通过'
  },
  {
    value:2,
    label:'审核失败'
  },
]
const { map: applyStatus_dMap, setOps: applyStatus_dOps } = mapHelper.setMap(applyStatus_d);
const { map: applyStatusMap, setOps: applyStatusOps } = mapHelper.setMap(applyStatus);
export {
  statusMap,
  setStatusOps,
  isTopMap,
  setIsTopOps,
  associationOrg,
  associationOrgMap,
  // talentTypeMap,
  // setTalentTypeOps,
  applyStatus,
  applyStatusMap,
  applyStatusOps,
  applyStatus_d,
  applyStatus_dMap,
  applyStatus_dOps
};
