<template>
  <div class="association">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
      :isSearch="false"
    >
      <template #headBtnSlot>
        <v-button text="新增" @click="toAdd"></v-button>
        <v-button text="返回" @click="toReturn"></v-button>
      </template>
      <template #searchSlot>
        <!-- <v-select
          clearable
          :options="associationList"
          v-model="searchParam.associationId"
          @change="$refs.list.search()"
          label="社群分类"
        /> -->
      </template>
      <template #operateSlot="scope">
        <v-button
          text="编辑"
          v-if="scope.row.canEdit == undefined || scope.row.canEdit"
          type="text"
          @click="toEdit(scope.row)"
        />
        <v-button text="删除" type="text" @click="toDelete(scope.row)" />
      </template>
    </v-list>
  </div>
</template>

<script>
import {
  getAssociaListUrl,
  getAssociaKindsListUrl,
  getInitInfoURL,
  getDeleteListUrl,
} from "./api.js";
import { statusMap, setStatusOps, isTopMap, setIsTopOps } from "./map.js";
import { createHTMLVNode, createImgVNode } from "@/utils/utils.js";

export default {
  name: "partnerList",
  data() {
    return {
      id: "",
      searchParam: {
        associationId: "",
        parentId: this.$route.query.id,
        associationLevel: "2",
      },
      associationList: [],
      talentTypeMap: {},
      isTopOps: setIsTopOps(),
      statusOps: setStatusOps(),
      tableUrl: getAssociaListUrl,
      headers: [
        {
          prop: "sort",
          label: "排序",
        },
        {
          prop: "associationName",
          label: "社群分类",
        },
        {
          prop: "associationLevelName",
          label: "级别",
        },
        {
          prop: "picture",
          label: "图片",
          formatter: (row, prop) => createImgVNode(this, row, prop),
        },
        {
          prop: "ageStage",
          label: "社群年龄",
        },
      ],
    };
  },
  created() {
    this.$setBreadList("社群二级分类");
  },
  computed: {
    createTime: {
      get() {
        return !this.searchParam.createTimeS && !this.searchParam.createTimeE
          ? []
          : [this.searchParam.createTimeS, this.searchParam.createTimeE];
      },
      set(val) {
        [this.searchParam.createTimeS, this.searchParam.createTimeE] = val || [
          "",
          "",
        ];
      },
    },
  },
  mounted() {
    this.getAssociationList();
  },
  methods: {
    todo() {
      console.log(8777);
    },
    toReturn() {
      this.$router.go(-1);
    },
    toAdd() {
      this.$router.push({
        name: "associationEdit",
      });
    },
    toDelete(item) {
      this.id = item.id;
      this.$confirm("确定要删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.delete();
        })
        .catch(() => {});
    },
    delete() {
      let params = {
        id: this.id,
      };
      this.$axios.delete(`${getDeleteListUrl}`, { params }).then((res) => {
        if (res.code === 200) {
          this.$refs.list.search();
        }
      });
    },
    toEdit(row) {
      this.$router.push({
        name: "associationEdit",
        query: { id: row.id, communityId: row.communityId },
      });
    },
    getAssociationList() {
      this.associationList = [];
      this.$axios.get(`${getAssociaKindsListUrl}`).then((res) => {
        if (res.code === 200) {
          res.data.associationLv1List.forEach((item) => {
            item.label = item.value;
            item.value = item.key;
            this.associationList.push(item);
          });
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.association {
  box-sizing: border-box;
  height: 100%;
}
</style>
